import React from "react";

import DocsHelmet from "components/docs/shared-components/DocsHelmet";
import { Col, Row } from "react-bootstrap";
import HeaderBar from "components/docs/layout/HeaderBar";
import CrossGuideLinks from "components/docs/navigation/CrossGuideLinks";
import { Link, PageProps } from "gatsby";
import DocumentationSection from "components/docs/DocumentationSection";
import LINKS from "components/docs/navigation/links";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { StaticPageContainer } from "components/docs/shared-components/PageContainers";

export const TitleBadgeCenteredDiv = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Creates an Overview for Field Mapping guide
 */
const FieldMappingOverview = ({
  location,
}: PageProps<unknown, unknown, Record<string, unknown> | null>) => {
  const title = "Field Mapping";
  const description =
    "Map custom fields and other non-mapped data from your users' platforms to target fields on your Merge Common Models.";

  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description} />
      <DocumentationSection title="Overview" className="mb-9">
        <>
          <p>
            Merge Field Mapping allows you or your users to map data from your users&apos; platforms
            to new fields on your Merge Common Models.
          </p>
          <p>
            <Link to={LINKS.REMOTE_DATA.linkTo}>Remote Data</Link> must be enabled to create target
            fields and mappings on a Common Model. Mapped data can be anything included in Remote
            Data, including fields, arrays, and objects.
          </p>
          <p>
            This feature is accessible via Merge Dashboard across all integrations that support
            Remote Data and is available to <strong>Professional</strong> and{" "}
            <strong>Enterprise</strong> customers.
          </p>
          <p className="mt-9">Creating a Field Mapping involves two main actions:</p>
          <p>
            <strong>Step 1</strong> -{" "}
            <Link to={LINKS.MERGE_FIELD_MAPPING_TARGET_FIELDS.linkTo}>
              Adding a <strong className="font-weight-bold">target field</strong> to a Merge Common
              Model
            </Link>
          </p>
          <p>
            <strong>Step 2</strong> -{" "}
            <Link to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING.linkTo}>
              Mapping a third-party app field
            </Link>{" "}
            (also known as an <strong className="font-weight-bold">origin field</strong>) to the
            target field:
          </p>
          <ul>
            <li>
              <Link to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_ACROSS_INTEGRATION.linkTo}>
                For all Linked Accounts across an integration
              </Link>
            </li>
            <li>
              <Link to={LINKS.MERGE_FIELD_MAPPING_CREATE_MAPPING_FOR_LINKED_ACCOUNT.linkTo}>
                For a specific Linked Account
              </Link>
            </li>
          </ul>
          <StaticImage
            src="../../../assets/images/docs/guides/field-mapping/FieldMappingOverviewPic.png"
            alt="Field Mapping Overview Picture"
            placeholder="none"
            layout="constrained"
            width={1127}
            className="mb-3"
          />
        </>
      </DocumentationSection>
      <CrossGuideLinks location={location} usesQuaternaryLinks />
    </StaticPageContainer>
  );
};

export default FieldMappingOverview;
